.header-upper{
    background-color: rgba(208, 11, 145, 0.246) !important;
}

.input-group > .rbt:not(:last-child) .form-control{
    background-color: rgba(155, 71, 128, 0.246) !important;
    border: none ;
    border-radius: 30px;
}

.input-group > .rbt + span{
    border: none ;
    border-radius: 30px;
}
.input-group > .rbt:not(:last-child) .form-control::placeholder{
    color: white !important;
    margin-left: 20px !important;
    position: relative;
    left: 10px;
}

.input-group > .rbt:not(:last-child) .form-control:focus{
    color: white !important;
    
    
}

.rbt + .input-group-text{
    background-color: rgba(208, 11, 146, 0.697)!important;
    color: white;
}

.logout-button{
    background-color: rgba(208, 11, 145, 0.246);
}


.card-count{
    position: relative;
    left: -10px;
    top: -13px;
    background-color: #FB00FF;
}

.pink-login{
   color: rgba(208, 11, 146, 0.697);
}

.banner-image{
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.nav-bar-header{
    background-color: rgba(208, 11, 145, 0.246) !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    
}

.head-logo{
    width: 200px;
    height: 100px;
    object-fit: cover;

}

.order-cart{
    border: 1px solid grey;
    border-radius: 20px;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.pink-classe{
    background-color: #FB00FF;
    width: 10px;
    height: 20px;
}



.toggle-button{
    background-color: rgba(208, 11, 145, 0.246) ;
    border: none;
    color: #FB00FF;
}

.toggle-button:hover {
    background-color: rgba(208, 11, 146, 0.577) ;
}

.search-button{
    background-color: #FB00FF ;
    border: none;
    
}

.search-input{
    width: 70%;
    border-radius: 30px !important;
    
}

.search-input input{
    border-radius: 30px !important;
    border: 1px solid #fb00ff49 !important;
}

.search-input input:focus{
    border: 1px solid #fb00ff49 !important;
}

.search-input + button {
    border-radius: 0px 30px 30px 0px !important;
    position: relative;
    left: -41px;
}

.search-input + button:hover {
   background-color: #fb00ff9a;
}

.toggle-login .dropdown-menu{
    position: relative;
    top: 35px;
    left: -92px;
}

.header-layout-items{
    position: relative;
    top: -80px;
    width: 100%;
    justify-content: space-between;
}

.navbar-large-screen{
    padding: 0px 10px 0px 10px;
    background-color: rgba(208, 11, 146, 0.697);
    position: relative;
    top: 8px;
}

.navbar-small-screen{
    display: none;
}

.card-price-text{
    color: rgba(208, 11, 146, 0.697);
}

.checkout-layout-sm{
    display: none;
}

.category-blog{
    color: rgb(107, 107, 107);
    font-family: 'Courier New', Courier, monospace;
    margin-left: 10px;
}

.pagination-btn{
    background-color: #FB00FF;
    color: white;
    border: none;
}

.blog-cover{
    width: 100%;
    height: 450px;
    object-fit: cover;
}

.coupon-code input{
    border-radius: 30px !important;
    border: 1px solid #fb00ff49 !important;
    padding: 10px;
    width: 300px;
    margin-right: 10px;
} 

.coupon-code button {
    border-radius: 30px !important;
    padding: 10px 20px !important;
    background-color: #FB00FF;
    color: white;
    border: none;
}

.promo-tag{
    background-color: rgba(230, 63, 205, 0.487);
    color: white;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 10px;
    margin-left: 10px;
    position: relative;
    top: -4px;
    
}

.out-of-stock {
    background-color: rgb(242, 185, 222);
    pointer-events: none;
    color: white;
}

.main-product-details .third-section div{
   display: flex;
   align-items: baseline !important;
}

.quantity-layout-2 {
    gap: 3px;
    margin-left: 0px !important;
    white-space: nowrap;
}

.quantity-layout .product-heading{
    margin-right: 3px;
}

.main-product-details .third-section .desc{
    display: flex !important;
    flex-direction: column !important;
}

.main-product-details .third-section .desc .product-data{
    padding-top: 0px !important;
}

.ingredient{
    display: flex;
    flex-direction: column;
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
    .navbar-large-screen {
        display: none !important;
    }

    .navbar-small-screen {
        display: flex !important;
        text-align: center;
        
    }

    .navbar-small-screen .navbar-nav{
        width: 100%;
    }

    .product-title.card-title.h5{
        font-size: 0.9em !important;
        display: flex;
        flex-direction: column-reverse;
        padding-top: 6px;
    }

    .product-title .promo-tag{
        text-align-last: center;
    }

    

    .card-text div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .desc{
        font-size: 0.8em !important;
    }
    .card-price-text{
        font-size: 1em !important;
        margin: 0px !important;
    }

    .card-text .text-danger{
        font-size: 0.8em !important;
    }

    .main-product-details .third-section .desc{
        display: flex !important;
        flex-direction: column !important;
    }

    .main-product-details .third-section .desc .product-data{
        padding-top: 0px !important;
    }


    .review-head{
        display: flex !important;
        flex-direction: column !important;
    }
    .review-head div{
        width: 100% !important;
    }

    .review-head  > div p{
        width: 100% !important;
    }


    
    

    .header-container{
        display: flex;
        flex-direction: column !important;
    }

    

    .search-info-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
    }

    .search-info-content div{
        width: 90% !important;
        margin: 5px;
    }

    .user-info-container .navbar-nav{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .none-in-sm{
        display: none;
    }
    
    .footer-sm{
        text-align: center;
    }

    div.social-icons{
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .newsletter-input-sm{
        margin-top: 10px;
    }

    .card-img-top{
        width: 160px;
        height: 170px !important;
        object-fit: cover;
    }
    .card{
        width: 10rem !important;
    }
    
    .card.card-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    

    .add-cart-button{
        margin-left: 18px !important;
    }

    

    .services-section{
        display: none;
    }
    .dropdown-user-info{
        position: absolute !important;
    }
    
    .filter-input-layout{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .products-list{
        padding-left: 10px;
    }

    .see-product-button{
        padding: 9px 15px !important;
    }

    .submit-to-buy{
        white-space: nowrap;
        padding: 12px 14px !important;
        width: 100%;
    }

    .header-body-card{
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
    }

    .product-title{
        white-space: nowrap;
        overflow: hidden;
    }

    .other-product-images{
        display: none !important;
    }

    .quantity-layout{
        width: 100% !important;
    }

    .button-layout{
        margin: 0px 0px 0px 12px !important;
    }

    .auth-card{
        width: 98% !important;
    }
    
    .contact-inner-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-inner-wrapper>div {
        width: 100%;
    }
    .carousel + section>.container-xxl{
        padding-left: 0px !important;
    }
    .carousel + section>.container-xxl>.container{
        padding-left: 10px !important;
    }

    .paiment-button-layout{
        display: flex;
        flex-direction: column-reverse;
    }
    .paiment-button-layout>a{
        margin-top: 15px;
        width: 100%;
        text-align: center;
    }

    .paiment-button-layout>div>div{
        display: flex;
        justify-content: center;
        margin-top: 15px;
        margin-left: 20px ;
        padding-left: 20px;
        width: 100%;
        text-align: center;
    }
    .shipping-layout{
        width: 100% !important;
    }
    .button-whislist-layout{
        display: flex !important;
        flex-direction: column !important;
    }

    .button-whislist-layout>a{
       white-space: nowrap;
        padding: 12px 18px !important;
    }

    .quantity-col>div>input{
        width: 145%;
    }



    .checkout-button-layout{
        display: flex !important;
        flex-direction: column-reverse !important;
        justify-content: space-between !important;
        width: 100%;
        height: 150px;
    }

    .checkout-layout{
        display: flex !important;
        flex-direction: column-reverse !important;
        width: 100%;
    }

    .checkout-layout-md{
        display: none;
    }

    .checkout-layout-sm{
        display: block;
    }

    .order-cart{
        font-size: 0.80em !important;
        padding: 20px 10px !important;
        
    }

    .order-cart h5{
        font-size: 0.90em !important;
       
    }

   .info-order-layout h6{
        font-size: 1em !important;
   }

   .info-order-layout:last-child h3{
        font-size: 1.80em !important;
   }

    .view-product{
        display: none;
    }

    .order-product-layout{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }

    div.social_icons{
        display: flex;
        justify-content: center;
    }
}


@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  

  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 992px) and (max-width: 1023px){
    .navbar-nav>.nav-link{
        white-space: nowrap;
    }

    .card{
        width: 14rem !important;
        height: 400px !important;
        overflow: hidden;
        
    }

    .add-cart-text{
        white-space: nowrap;
    }

    .card-body>.button{
        padding: 10px 20px !important;
    }

    .card-img-top{
        width: 225px;
        height: 180px !important;
        object-fit: cover;
        
        
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    
    .navbar-large-screen {
        display: none !important;
    }

    .navbar-small-screen {
        display: flex !important;
        text-align: center;
        
    }

    .navbar-small-screen .navbar-nav{
        width: 100%;
    }



    .main-product-details .third-section .order-product-layout{
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
    }

    

    .main-product-details .third-section .desc .product-data{
        padding-top: 0px !important;
    }
    

    .header-container{
        display: flex;
        flex-direction: column !important;
    }

    

    .search-info-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
    }

    .search-info-content div{
        width: 90% !important;
        margin: 5px;
    }
    .user-info-container>div>div{
        display: flex;
        justify-content: center;
    }

    .user-info-container .navbar-nav{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

  
    
    .footer-sm{
        text-align: center;
    }

    div.social-icons{
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .newsletter-input-sm{
        margin-top: 10px;
    }

    .card-img-top{
        width: 208px;
        height: 170px !important;
        object-fit: cover;
        
    }
    .card{
        width: 13rem !important;
        overflow: hidden;
    }
    
    .card.card-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    

    .add-cart-button{
        margin-left: 18px !important;
    }
    
    .services-section{
        display: none;
    }
    .dropdown-user-info{
        position: absolute !important;
    }

    .dropdown-user-info{
        position: absolute !important;
    }
    
    .filter-input-layout{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .products-list{
        padding-left: 10px;
    }

    .see-product-button{
        padding: 9px 15px !important;
    }

    .submit-to-buy{
        white-space: nowrap;
        padding: 12px 14px !important;
        width: 100%;
    }

    .header-body-card{
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
    }

    .product-title{
        white-space: nowrap;
        overflow: hidden;
    }

    .other-product-images{
        display: none !important;
    }

    .quantity-layout{
        width: 100% !important;
    }

    .button-layout{
        margin: 0px 0px 0px 12px !important;
    }

    .auth-card{
        width: 98% !important;
    }
    
    .contact-inner-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-inner-wrapper>div {
        width: 100%;
    }
    .carousel + section>.container-xxl{
        padding-left: 0px !important;
    }
    .carousel + section>.container-xxl>.container{
        padding-left: 10px !important;
        margin: 0px !important;
        max-width: 100% !important;
    }

    .paiment-button-layout{
        display: flex;
        flex-direction: column-reverse;
    }
    .paiment-button-layout>a{
        margin-top: 15px;
        width: 100%;
        text-align: center;
    }

    .paiment-button-layout>div>div{
        display: flex;
        justify-content: center;
        margin-top: 15px;
        margin-left: 20px ;
        padding-left: 20px;
        width: 100%;
        text-align: center;
    }
    .shipping-layout{
        width: 100% !important;
    }
    .button-whislist-layout{
        display: flex !important;
        flex-direction: column !important;
    }

    .button-whislist-layout>a{
       white-space: nowrap;
        padding: 12px 18px !important;
    }

    .quantity-col>div>input{
        width: 100%;
    }



    .checkout-button-layout{
        display: flex !important;
        flex-direction: column-reverse !important;
        justify-content: space-between !important;
        width: 100%;
        height: 150px;
    }

    .checkout-layout{
        display: flex !important;
        flex-direction: column-reverse !important;
        width: 100%;
    }

    .checkout-layout-md{
        display: none;
    }

    .checkout-layout-sm{
        display: block;
    }

    .order-cart{
        font-size: 0.80em !important;
        padding: 20px 10px !important;
        
    }

    .order-cart h5{
        font-size: 0.90em !important;
       
    }

   .info-order-layout h6{
        font-size: 1em !important;
   }

   .info-order-layout:last-child h3{
        font-size: 1.80em !important;
   }

    .view-product{
        display: none;
    }

    .nav-bar-header>div{
        max-width: 98% !important;
    }
    
    .search-input + button{
        border-radius: 50% !important;
    } 
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    .navbar-large-screen {
        display: none !important;
    }

    .navbar-small-screen {
        display: flex !important;
        text-align: center;
        
    }

    .navbar-small-screen .navbar-nav{
        width: 100%;
    }

    

    .header-container{
        display: flex;
        flex-direction: column !important;
    }

    

    .search-info-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
    }

    .search-info-content div{
        width: 90% !important;
        margin: 5px;
    }

    .user-info-container .navbar-nav{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .none-in-sm{
        display: none;
    }
    
    .footer-sm{
        text-align: center;
    }

    div.social-icons{
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .newsletter-input-sm{
        margin-top: 10px;
    }

    .card-img-top{
        width: 208px;
        height: 170px !important;
        object-fit: cover;
        
    }
    .card{
        width: 13rem !important;
        overflow: hidden;
    }
    
    .card.card-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

   

    .add-cart-button{
        margin-left: 18px !important;
    }
    
    .services-section{
        display: none;
    }
    .dropdown-user-info{
        position: absolute !important;
    }

    .dropdown-user-info{
        position: absolute !important;
    }
    
    .filter-input-layout{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .products-list{
        padding-left: 10px;
    }

    .see-product-button{
        padding: 9px 15px !important;
    }

    .submit-to-buy{
        white-space: nowrap;
        padding: 12px 14px !important;
        width: 100%;
    }

    .header-body-card{
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
    }

    .product-title{
        white-space: nowrap;
        overflow: hidden;
    }

    .other-product-images{
        display: none !important;
    }

    .quantity-layout{
        width: 100% !important;
    }

    .button-layout{
        margin: 0px 0px 0px 12px !important;
    }

    .auth-card{
        width: 98% !important;
    }
    
    .contact-inner-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-inner-wrapper>div {
        width: 100%;
    }
    .carousel + section>.container-xxl{
        padding-left: 0px !important;
    }
    .carousel + section>.container-xxl>.container{
        padding-left: 10px !important;
        margin: 0px !important;
        max-width: 100% !important;
    }

    .paiment-button-layout{
        display: flex;
        flex-direction: column-reverse;
    }
    .paiment-button-layout>a{
        margin-top: 15px;
        width: 100%;
        text-align: center;
    }

    .paiment-button-layout>div>div{
        display: flex;
        justify-content: center;
        margin-top: 15px;
        margin-left: 20px ;
        padding-left: 20px;
        width: 100%;
        text-align: center;
    }
    .shipping-layout{
        width: 100% !important;
    }
    .button-whislist-layout{
        display: flex !important;
        flex-direction: column !important;
    }

    .button-whislist-layout>a{
       white-space: nowrap;
        padding: 12px 18px !important;
    }

    .quantity-col>div>input{
        width: 100%;
    }



    .checkout-button-layout{
        display: flex !important;
        flex-direction: column-reverse !important;
        justify-content: space-between !important;
        width: 100%;
        height: 150px;
    }

    .checkout-layout{
        display: flex !important;
        flex-direction: column-reverse !important;
        width: 100%;
    }

    .checkout-layout-md{
        display: none;
    }

    .checkout-layout-sm{
        display: block;
    }

    .order-cart{
        font-size: 0.80em !important;
        padding: 20px 10px !important;
        
    }

    .order-cart h5{
        font-size: 0.90em !important;
       
    }

   .info-order-layout h6{
        font-size: 1em !important;
   }

   .info-order-layout:last-child h3{
        font-size: 1.80em !important;
   }

    .view-product{
        display: none;
    }

    .nav-bar-header>div{
        max-width: 98% !important;
    }
    
    .search-input + button{
        border-radius: 50% !important;
    } 

    
  }
  
  
  